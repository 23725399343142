

.hide-transition{
    transition: opacity 0.5s linear;
    opacity: 0;
}

.show-transition{
    transition: opacity 0.5s linear;
    opacity: 1;
}

.hide-transition--fast{
    transition: opacity 0.3s linear;
    opacity: 0;
}

.show-transition--fast{
    transition: opacity 0.3s linear;
    opacity: 1;
}

.hide-transition--faster{
    transition: opacity 0.25s linear;
    opacity: 0;
}

.show-transition--faster{
    transition: opacity 0.25s linear;
    opacity: 1;
}