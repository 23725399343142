/* #region GLOBAL STYLES */
body {
    margin: 0;
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    overflow: hidden;
    background: var(--surface-surface-primary, #000);
    color: var(--text-primary-color, #fff);
}

html {
    font-size: 16px; /* RULE SET: 1rem = 16px */
    color: var(--text-primary-color, #fff);
}

div {
    color: var(--text-primary-color, #fff);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
    /* DISABLE IPHONE OVERSCROLLING */
    /* overscroll-behavior-y: none; */
}

::-webkit-scrollbar {
    height: 0px;
    width: 0px;
}
.modal-content ::-webkit-scrollbar {
    height: 12px;
}
::-webkit-scrollbar-thumb {
    background-color: #333f50;
}
::-webkit-scrollbar-track {
    background-color: #f5f5f500;
    -webkit-box-shadow: inset 0 0 6px transparent;
}
a {
    text-decoration: none !important;
}
/* #endregion */

/* #region MAIN APP LAYOUT */
.mainWrapper {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    position: relative;
}

.mainWrapper.feedPage {
    overflow: hidden !important;
}

.mainRightMenu {
    display: flex;
    width: 420px;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;

    background: var(--surface-surface-secondary, #121212);
    border-style: solid;
    border-color: var(--border-border-primary-10, rgba(166, 166, 166, 0.1));
    border-width: 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: auto;

    padding: 16px 24px;
    box-sizing: border-box;
}

.leftSide {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    gap: 0px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
}

.mainHeader {
    display: flex;
    width: 100%;
    height: 52px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.mainContent {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    /* padding: 14px 24px 0 24px; */
    box-sizing: border-box;
    overflow: hidden;
}

.mainFooter {
    display: flex;
    width: 100%;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
}

.yarl__portal {
    right: 420px !important;
}

.BOTagLightBox .yarl__slide_image {
    margin-bottom: 120px;
}
.BOTagLightBox .yarl__navigation_next,
.BOTagLightBox .yarl__navigation_prev {
    top: calc(50% - 60px);
}
/* #endregion */

/* #region GOOGLE MAPS AUTO-COMPLETE STYLES */
.pac-container {
    background: #121212 !important;
    border: 1px solid #383838;
    border-radius: 10px;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 19px;
    padding-top: 8px;
    z-index: 9999999;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
}
.pac-item-query {
    font-size: 14px;
    padding-right: 3px;
    color: #fff;
}
.pac-matched {
    font-weight: 600;
    color: #fff;
}
.pac-item {
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    border-top: 1px solid #383838;
}
.pac-item:hover {
    background-color: #212121;
}
.pac-item:first-child {
    border: none;
    padding-top: 0;
}
/* #endregion */

/* #region TOASTS LAYOUT FROM REACT-TOASTIFY */

.Toastify__toast-container {
    width: 430px !important;
    z-index: 9999999999 !important;
}

.Toastify__toast-theme--dark {
    background: var(--surface-surface-tertiary) !important;
}

.Toastify__toast-icon {
    -webkit-margin-end: 15px !important;
    margin-inline-end: 15px !important;
    width: 45px !important;
}

.Toastify__toast-icon img {
    width: 35px !important;
}
/* #endregion */

/* #region SWEET ALERT CONFIRM DIALOG //TODO: WILL BE CREATED A NEW COMPONENT FOR DIALOG? */
.swal2-container.swal2-center > .swal2-popup {
    padding: 10px 20px !important;
}

.swal2-icon {
    margin: 10px auto 40px !important;
}

.swal2-actions {
    margin: 0 0 10px !important;
}

.swal2-html-container {
    font-size: 14px !important;
    line-height: normal;
    text-align: center;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    color: #d1d1d1 !important;
    margin: 0 0 40px !important;
}

.swal2-styled.swal2-confirm {
    color: #d1d1d1;
    font-size: 14px !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    width: 220px !important;
    height: 35px;
    margin: auto !important;
}

.swal2-styled.swal2-deny {
    color: #d1d1d1;
    font-size: 14px !important;
    font-family: "Roboto" !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
    width: 220px !important;
    height: 35px;
    margin: 0 0 0px 20px !important;
}

.swal2-styled {
    padding: 5px !important;
}

.swal2-styled:hover {
    background-color: #3c7fc1 !important;
}

.swal2-styled.swal2-default-outline:focus {
    box-shadow: none !important;
}

/* #endregion */

/* #region MUI MOBILE DRAWER */

.MuiPaper-root,
.MuiDrawer-paper {
    background-color: #121212 !important;
    color: #ffffff !important;
    /* height: 100% !important; */
}

.AnchorDrawer.partial .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.MuiBox-root > div {
    min-height: 100vh !important;
}
.custom-checkout-class {
    margin-right: 0px !important;
}

/* #endregion */
