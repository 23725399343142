/* media for less than 992 */
@media only screen and (max-width: 992px) {
    /*::-webkit-scrollbar {
        height: 0;
        width: 0;
    }*/

    .mainWrapper {
        flex-direction: column;
        height: 100dvh;
        overflow: scroll;
    }
    .mainRightMenu {
        width: 100%;
        height: fit-content !important;
        border-width: 1px 0px 0px 0px;
        overflow: visible !important;
        /* min-height: 756px !important; */
        padding: 0;
    }
    .leftSide {
        width: 100%;
        height: fit-content !important;
        flex-shrink: 0 !important;
        /* margin-top: 52px !important; */
    }
    .mainHeader {
        width: 100%;
        height: 52px;
        flex-shrink: 0;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
    }
    .mainWrapper .header {
        padding: 24px 16px !important;

        position: fixed;
        top: 0px;
        z-index: 99999;
    }
    .mainContent {
        width: 100%;
        height: fit-content !important;
        padding: 0px 0px !important;

        margin-top: 52px !important;
    }
    .mainFooter .footer {
        padding: 16px 16px !important;
    }
    .AnchorDrawer {
        z-index: 9999999 !important;
    }
    .AnchorDrawer .MuiModal-backdrop {
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        -moz-backdrop-filter: blur(6px);
        -o-backdrop-filter: blur(6px);
        -ms-backdrop-filter: blur(6px);
    }
    .BOTagLightBox .yarl__slide_image {
        margin-bottom: 70px;
    }
    .BOTagLightBox .yarl__navigation_next,
    .BOTagLightBox .yarl__navigation_prev {
        top: calc(50% - 35px);
    }

    /* #region FEED PAGE */

    .FeedPageContainer {
        padding: 14px 16px 0 16px !important;
    }
    .FeedPageContainer.pad-container {
        padding: 0px !important;
    }
    .boImagePage .mainRightMenu {
        /* padding-bottom: 65px !important; */
        /*height: calc(100% + 52px) !important; */
        height: auto !important;
    }
    .feedPage .mainFooter {
        position: fixed;
        bottom: 0;
    }
    /* #endregion */

    /* #region BOIMAGE PAGE */
    .BOImageContainer {
        height: fit-content !important;
    }

    /* .BOImageContainer .ImageBeaconsContainer {
        min-height: 400px !important;
    } */
    /* .googleMapsContainer {
        height: 500px !important;
    } */

    .user-location-panel-container {
        height: fit-content !important;
    }

    .user-location-panel {
        min-height: unset !important;
        height: fit-content !important;
        overflow: visible !important;

        padding: 16px !important;
    }

    .user-location-panel .buttons-section {
        width: 100% !important;
        align-items: baseline !important;
    }
    /* #endregion */

    /* #region CHECKOUT PANEL */

    .checkout-panel {
        padding: 16px !important;
    }

    /* #endregion */
}
