:root {
    --text-text-primary: #ffffff;
    --text-text-secondary: #8c8c8c;
    --text-text-success: #69c23f;
    --text-text-error: #ff0000;
    --text-text-info: #0060cc;

    --buttons-button-primary: #f7665f;
    --buttons-button-secondary: #212121;
    --buttons-button-secondary-active: #373737;
    --buttons-button-tertiary: #232832;

    --wrap-wrap-gray: #a6a6a61a; /* 10% */
    --wrap-wrap-green: #69c23f1a; /* 10% */
    --wrap-wrap-blue: #0060cc1a; /* 10% */
    --wrap-wrap-brand: #f7665f1a; /* 10% */

    --icons-icon-white: #ffffff;
    --icons-icon-blue: #0060cc;
    --icons-icon-red: #ff0000;

    --surface-surface-brand: #f7665f;
    --surface-surface-primary: #000000;
    --surface-surface-secondary: #121212;
    --surface-surface-tertiary: #212121;
    --surface-surface-overlay: #0d0d0d; /* 80% */

    --border-border-brand: #f7665f;
    --border-border-primary-10: #a6a6a633;
    --border-border-primary: #a6a6a633; /* 20% */
    --border-border-secondary: #8c8f93;
    --border-border-tertiary: #373737;
    --border-border-quaternary: #a6a6a61a; /* 10% */
}

/* #region BACKGROUNDS & LINES */
.surface-brand {
    background: var(--surface-surface-brand) !important;
}
.surface-primary {
    background: var(--surface-surface-primary) !important;
}
.surface-secondary {
    background: var(--surface-surface-secondary) !important;
}
.surface-tertiary {
    background: var(--surface-surface-tertiary) !important;
}
.surface-overlay {
    background: var(--surface-surface-overlay) !important;
}

.wrap-gray {
    background: var(--wrap-wrap-gray) !important;
}
.wrap-green {
    background: var(--wrap-wrap-green) !important;
}
.wrap-blue {
    background: var(--wrap-wrap-blue) !important;
}
/* #endregion */

/* #region TEXT & ICONS COLORS */
.text-primary {
    color: var(--text-text-primary) !important;
}
.text-secondary {
    color: var(--text-text-secondary) !important;
}
.text-success {
    color: var(--text-text-success) !important;
}
.text-error {
    color: var(--text-text-error) !important;
}
.text-info {
    color: var(--text-text-info) !important;
}

.icon-white {
    color: var(--icons-icon-white) !important;
}
.icon-blue {
    color: var(--icons-icon-blue) !important;
}
.icon-red {
    color: var(--icons-icon-red) !important;
}

/* #endregion */

/* #region BORDERS */
.border-brand {
    border-color: var(--border-border-brand) !important;
}
.border-primary-10 {
    border-color: var(--border-border-primary-10) !important;
}
.border-primary {
    border-color: var(--border-border-primary) !important;
}
.border-secondary {
    border-color: var(--border-border-secondary) !important;
}
.border-tertiary {
    border-color: var(--border-border-tertiary) !important;
}
.border-quaternary {
    border-color: var(--border-border-quaternary) !important;
}
/* #endregion */

/* #region BUTTONS */
.button-primary {
    background: var(--buttons-button-primary) !important;
}
.button-secondary {
    background: var(--buttons-button-secondary) !important;
}
.button-secondary-active {
    background: var(--buttons-button-secondary-active) !important;
}
.button-tertiary {
    background: var(--buttons-button-tertiary) !important;
}
/* #endregion */
